import React from 'react';
import styled from 'styled-components';
import PageProvider from 'store/PageProvider';
import { useGlobalContext } from 'store/GlobalProvider';
import GlobalStyle from './GlobalStyle';
import Helmet from 'react-helmet';
import theme from 'styles/theme';
import { Footer } from './Footer';
import { Header } from './Header';
import { Newsletter } from 'components/Newsletter';
import { useUpdateEffect } from 'react-use';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import beziers from 'core/beziers';
import useScrollEnabled from 'hooks/useScrollEnabled';

const Layout = ({ children, location, pageContext }) => {
  const resetScroll = useScrollEnabled();
  const layerTop = useAnimation({ scaleY: 0.25 });
  const layerBot = useAnimation({ scaleY: 0.25 });
  const { pageType } = pageContext;
  const isPage404 = pageType === '404';
  const { scrollEnabled, headerHeight, wh } = useGlobalContext();

  const animateLayers = async (layer) => {
    await layer.set({ scaleY: 0 });
    await layer.set({ originY: 1 });
    await layer.start({
      scaleY: 1,
      transition: { type: 'tween', ease: beziers.principle, duration: 0.6 },
    });
    await layer.set({ originY: 0 });
    resetScroll();
    window.scrollTo(0, 0);
    return await layer.start({
      scaleY: 0,
      transition: { type: 'tween', duration: 0.6, ease: beziers.principle },
    });
  };

  useUpdateEffect(() => {
    layerBot.stop();
    layerTop.stop();
    animateLayers(layerBot);
    setTimeout(() => animateLayers(layerTop), 400);
  }, [pageContext.url]);

  return (
    <PageProvider pageContext={pageContext}>
      <Helmet>
        {/* eslint-disable */}
        <link rel="preconnect" href="https://eminente.cdn.prismic.io" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/images/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color={theme.colors.metaThemeColor} />
        <link rel="shortcut icon" href="/images/favicons/favicon.ico" />
        <meta name="msapplication-config" content="/images/favicons/browserconfig.xml" />
        <meta name="theme-color" content={theme.colors.metaThemeColor} />
        {/* eslint-enable */}
      </Helmet>
      <GlobalStyle scrollEnabled={scrollEnabled} viewportHeight={wh} headerHeight={headerHeight} />
      {!isPage404 && <Header />}
      <AnimatePresence mode="wait" initial={false}>
        <Main
          key={pageContext.url}
          initial={{ y: 300 }}
          animate={{
            y: 0,
            transition: { type: 'tween', ease: beziers.expoOut, duration: 0.75 },
          }}
          exit={{ y: 0, transition: { duration: 1 } }}
        >
          {children}
          {!isPage404 && <Footer />}
        </Main>
      </AnimatePresence>
      <Layer animate={layerTop} initial={{ scaleY: 0, originY: 1 }} position="top" />
      <Layer animate={layerBot} initial={{ scaleY: 0, originY: 1 }} position="bot" />
      <Newsletter />
    </PageProvider>
  );
};

const Layer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  z-index: ${({ position }) => theme.zIndex[position === 'top' ? 'layerTop' : 'layerBot']};
  background: ${({ position, theme }) => theme.colors[position === 'top' ? 'accent' : 'black']};
`;

const Main = styled(motion.main)``;

export default Layout;
