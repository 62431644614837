import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import { useIsomorphicLayoutEffect } from 'react-use';
import styled from 'styled-components';

const CACHE = [];

function getImageUrlAtWidth(targetWidth, image) {
  const { width, height } = image.dimensions;
  const scale = targetWidth / width;
  const w = Math.round(width * scale);
  const h = Math.round(height * scale);

  return image.url.replace(`w=${width}`, `w=${w}`).replace(`h=${height}`, `h=${h}`);
}

function getSrcSet(image) {
  const breakpoints = [768, 1024, 1280, 1440, 1600, 1920, 2560];

  return (
    breakpoints
      .concat([image.dimensions.width])
      .sort()
      // Set srcSet only for smaller images than original,
      // but keeping a 2x possibility
      .filter((bp) => bp <= image.dimensions.width * 2)
      // Generate url
      .map((bp) => `${getImageUrlAtWidth(bp, image)} ${bp}w`)
      // Generate srcSet
      .join(', ')
  );
}

function getPreviewUrl(image) {
  const url = new URL(getImageUrlAtWidth(100, image));
  url.searchParams.append('blur', '100');
  return url.href;
}

const BLANK_PX =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const ImageComponent = forwardRef(
  (
    {
      dimensions,
      url: originalUrl,
      alt,
      loading = 'lazy',
      sizes: originalSizes = null,
      naturalSizing = false,
      Component = 'picture',
      animation = 'layer',
      ...others
    },
    $ref
  ) => {
    const url = new URL(originalUrl).href;
    const $preview = useRef(null);
    const $picture = useRef(null);
    const $element = $ref || $picture;
    const { isClient } = useGlobalContext();
    const [isLoaded, setLoaded] = useState(false);
    const [isPreviewVisible, setPreviewVisible] = useState(true);
    const [sizes, setSizes] = useState(originalSizes);
    const srcSet = getSrcSet({ url, dimensions });
    const previewSrc = getPreviewUrl({ url, dimensions });
    const paddingBottom = `${(dimensions.height / dimensions.width) * 100}%`;

    useEffect(() => {
      const observer = new ResizeObserver(([entry]) => {
        setSizes(`${Math.round(entry.contentRect.width)}px`);
      });

      if ($element.current && originalSizes === null) {
        observer.observe($element.current);
      } else {
        setSizes(originalSizes);
      }

      return () => observer.disconnect();
    }, [$element.current, setSizes, originalSizes, isClient]);

    useIsomorphicLayoutEffect(() => {
      const isInCache = CACHE.find((cached) => sizes === cached.sizes && cached.srcSet === srcSet);
      if (isInCache) {
        setPreviewVisible(false);
      }
    }, [sizes]);

    const onPreviewTransitionEnd = useCallback(() => {
      setPreviewVisible(false);
    }, [setPreviewVisible]);

    const onLoad = useCallback(
      (event) => {
        if (event.target.currentSrc === BLANK_PX) {
          return;
        }

        // Set image in cache at specific size to prevent animate again.
        if (!CACHE.find((cached) => sizes === cached.sizes && cached.srcSet === srcSet)) {
          CACHE.push({ sizes, srcSet });
        }

        if ($preview.current) {
          $preview.current.addEventListener('transitionend', onPreviewTransitionEnd);
        }

        setLoaded(true);
      },
      [onPreviewTransitionEnd, sizes, srcSet]
    );

    return (
      <Component
        ref={$element}
        {...others}
        data-loaded={isLoaded}
        key={isClient ? 'client' : 'server'}
      >
        {!naturalSizing && <span style={{ paddingBottom }} />}
        <Img
          isAbsolute={!naturalSizing}
          src={BLANK_PX}
          srcSet={sizes ? srcSet : null}
          data-srcset={!sizes ? srcSet : null}
          sizes={sizes}
          onLoad={onLoad}
          loading={loading}
          width={dimensions.width}
          height={dimensions.height}
          alt={alt}
        />
        {isPreviewVisible &&
          (animation === 'layer' ? (
            <Layer ref={$preview} />
          ) : (
            <Blurred ref={$preview} src={previewSrc} alt={alt} loading="eager" />
          ))}
      </Component>
    );
  }
);

ImageComponent.displayName = 'Image';

const Img = styled.img`
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : null)};
  top: ${({ isAbsolute }) => (isAbsolute ? 0 : null)};
  left: ${({ isAbsolute }) => (isAbsolute ? 0 : null)};
  width: ${({ isAbsolute }) => (isAbsolute ? '100%' : null)};
  height: ${({ isAbsolute }) => (isAbsolute ? '100%' : null)};
  object-fit: cover;
  object-position: center center;
  z-index: 0;
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.accent};
  height: 100%;
  z-index: 1;
  transform-origin: 50% 0;
  transition: transform 1s ${({ theme }) => theme.easings.principle};

  [data-loaded='true'] & {
    transform: scaleY(0);
  }
`;

const Blurred = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.8s;

  [data-loaded='true'] & {
    opacity: 0;
  }
`;

export const Image = styled(ImageComponent)`
  position: relative;
  display: block;

  & > span {
    display: block;
  }
`;
