export const createSmartRandom = (array) => {
  const count = array.map((_) => 0);

  const get = () => {
    const min = Math.min(...count);
    const index = Math.round((array.length - 1) * Math.random());
    if (count[index] === min) {
      count[index] = count[index] + 1;
      return array[index];
    } else {
      return get();
    }
  };

  return get;
};
