import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { RichText as PrismicRichText, Elements } from 'prismic-reactjs';
import { Link } from './Link';
import linkResolver from 'core/linkResolver';
import { fontCondensed, h4 } from 'styles/typography';
import { motion } from 'framer-motion';
import { Image } from './Image';
import { rem } from 'styles/utils';
import media from 'styles/media';
import { rgba } from 'polished';

const reactSerializer = function (type, element, content, children, index) {
  const key = `element-${type}-${index + 1}`;

  switch (type) {
    // Don't wrap images in a <p> tag
    case Elements.image:
      return (
        <figure key={key}>
          <Image {...element} />
          {/* <img src={element.url} alt={element.alt} /> */}
          {/* {!!element.alt && <figcaption>{element.alt}</figcaption>} */}
        </figure>
      );

    // Dynamic link with gatsby router
    case Elements.hyperlink:
      const url = linkResolver(element.data);

      return (
        <Link key={key} to={url} target={element.data.target}>
          {content}
        </Link>
      );

    case Elements.listItem:
    case Elements.oListItem:
      return (
        <li key={key}>
          <span>{children}</span>
        </li>
      );

    // Return null to stick with the default behavior for all other elements
    default:
      return null;
  }
};

const RichTextComponent = forwardRef(
  ({ dangerouslySetInnerHTML = false, animated = false, render, ...others }, ref) => {
    const Element = animated ? motion.div : 'div';
    if (dangerouslySetInnerHTML) {
      return <Element ref={ref} dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...others} />;
    } else {
      return (
        <Element ref={ref} {...others}>
          {PrismicRichText.render(render, linkResolver, reactSerializer)}
        </Element>
      );
    }
  }
);

RichTextComponent.displayName = 'RichTextComponent';

export const RichText = styled(RichTextComponent)`
  pointer-events: none;
  color: ${({ theme }) => theme.colors.font};

  [data-background='dark'] & {
    color: ${({ theme }) => rgba(theme.colors.white, 0.58)};
  }

  a {
    pointer-events: auto;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${h4}
    margin-top: 1em;
    margin-bottom: 1em;
  }

  p {
    margin: 1em 0;
  }

  figure {
    margin-top: ${rem(56)};
    margin-bottom: ${rem(56)};
    border-radius: 8px;

    picture,
    img {
      border-radius: inherit;
    }
  }

  i,
  em {
    font-style: italic;
  }

  b,
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  u {
    text-decoration: underline;
  }

  ul {
    list-style: disc inside;
  }

  ol {
    list-style: decimal inside;
  }

  table {
    background: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.dust};
    border: 2px solid ${({ theme }) => theme.colors.black};
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;

    th,
    td {
      border-top: 1px solid ${({ theme }) => theme.colors.dust};
      padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};
      font-weight: normal;
    }
  }
`;

export const RichTextEditorial = styled(RichText)`
  font-size: ${rem(28)};
  line-height: ${52 / 28};
  color: ${({ theme }) => theme.colors.black};

  [data-background='dark'] & {
    color: ${({ theme }) => theme.colors.white};
  }

  strong {
    ${fontCondensed};
    font-weight: bold;
    text-transform: uppercase;
    display: inline-flex;
    align-items: baseline;

    &:before {
      content: '';
      width: 1em;
      height: 1em;
      line-height: ${36 / 18};
      border-radius: 50%;
      display: block;
      background: currentColor;
      align-self: center;
      margin-right: 0.25em;
    }
  }

  ${media.mobile`
    font-size: ${rem(18)};
    line-height: ${32 / 18};
  `}
`;
