import easings from './easings';

const defaultGridSize = 40;
const designedDesktop = 1440;
const designedMobile = 400;
const baseFontSize = 16;

const theme = {
  defaultGridSize,
  designedDesktop,
  designedMobile,
  zIndex: {
    header: 11,
    menu: 10,
    layerTop: 9,
    layerBot: 8,
    newsletter: 7,
  },
  colors: {
    metaThemeColor: '#1A171B',
    black: '#1A171B',
    blackBorder: '#333333',
    blackLight: '#222222',
    font: '#666666',
    white: '#ffffff',
    border: '#eeeeee',
    accent: '#05F29B',
    beige: '#F8F7F2',
  },
  fontWeight: {
    normal: 'normal',
    medium: 500,
  },
  fontSize: {
    base: baseFontSize,
    normal: 14,
  },
  lineHeight: {
    base: 30 / 14,
    normal: 30 / 14,
  },
  spacing: {},
  easings,
};

export default theme;
