import styled from 'styled-components';
import media from 'styles/media';

export const Container = styled.div`
  width: 100%;
  padding-left: var(--margin);
  padding-right: var(--margin);
`;

export const ContainerGrid = styled(Container)`
  display: grid;
  grid-template-columns: repeat(8, minmax(10px, 1fr));
  grid-column-gap: var(--margin);

  ${media.mobile`
    grid-column-gap: calc(var(--margin) / 2);
  `}
`;
