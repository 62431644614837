import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { propsToDom } from 'core/helpers';
import { rem } from 'styles/utils';
import { useGlobalContext } from 'store/GlobalProvider';
import { useClickOutside } from 'hooks/useClickOutside';

const NewsletterComponent = (props) => {
  const $ref = useRef();
  const $iframe = useRef();
  const { isNewsletterOpen, setNewsletterOpen } = useGlobalContext();
  useClickOutside($ref, () => setNewsletterOpen(false));

  useEffect(() => {
    if ($iframe.current) {
      $iframe.current.onmessage = (e) => {
        console.log(e);
      };
    }
  }, []);

  return (
    <div {...propsToDom(props)} ref={$ref} data-open={isNewsletterOpen}>
      <iframe
        ref={$iframe}
        className="mj-w-res-iframe"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://app.mailjet.com/widget/iframe/6h3d/M2M"
        width="100%"
      ></iframe>
      <script
        type="text/javascript"
        src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
      ></script>
    </div>
  );
};

export const Newsletter = styled(NewsletterComponent)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${rem(8)};
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.newsletter};
  transform: translateY(100%);
  transition: transform 0.6s ${({ theme }) => theme.easings.principle};

  &[data-open='true'] {
    transform: translateY(0%);
  }

  iframe {
    position: relative;
    display: block;
    background: ${({ theme }) => theme.colors.black};
    border-radius: 8px;
    overflow: hidden;
  }
`;
