import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useIsomorphicLayoutEffect, useMeasure } from 'react-use';
import { propsToDom } from 'core/helpers';
import { usePageContext } from 'store/PageProvider';
import { Icon } from './Icon';
import { Link } from './Link';
import { rem } from 'styles/utils';
import { buttonReset } from 'styles/others';
import { Menu } from './Menu';
import { useGlobalContext } from 'store/GlobalProvider';
import { AnimatePresence, motion } from 'framer-motion';
import { fontNarrow } from 'styles/typography';
import beziers from 'core/beziers';
import media from 'styles/media';
import { useStickyScrollUp } from 'hooks/useStickyScrollUp';

const HeaderComponent = (props) => {
  const stickyStyle = useStickyScrollUp();
  const [$container, { height }] = useMeasure();
  const { isMenuOpen, setMenuOpen, menuTitle, setHeaderHeight } = useGlobalContext();
  const { siteData } = usePageContext();
  const { menu_default_title } = siteData;

  const breadcrumbProps = {
    initial: { y: '100%', opacity: 0 },
    animate: { y: '0%', opacity: 1 },
    exit: { y: '-100%', opacity: 0 },
    transition: {
      opacity: { ease: beziers.sineOut, duration: 0.2 },
      default: { ease: beziers.expoOut, duration: 0.5 },
    },
  };

  useIsomorphicLayoutEffect(() => {
    if (height > 0) {
      setHeaderHeight(height);
    }
  }, [height]);

  const onClickLink = (e) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    const internal = /^\/(?!\/)/.test(href);
    setMenuOpen(false);
    setTimeout(
      () => {
        if (internal) navigate(href);
        else window.location.href = href;
      },
      isMenuOpen ? 400 : 0
    );
  };

  return (
    <Fragment>
      <header {...propsToDom(props)} data-menu-open={isMenuOpen} style={stickyStyle}>
        <Container ref={$container}>
          <Logo to="/" onClick={onClickLink}>
            <Icon id="logo" />
            <Icon id="logo-mobile" />
          </Logo>
          <Breadcrumb>
            <AnimatePresence initial={false} mode="wait">
              {isMenuOpen ? (
                <motion.span key={menu_default_title} {...breadcrumbProps}>
                  {menu_default_title}
                </motion.span>
              ) : menuTitle ? (
                <motion.span key={menuTitle} {...breadcrumbProps}>
                  {menuTitle}
                </motion.span>
              ) : null}
            </AnimatePresence>
          </Breadcrumb>
          <Toggle onClick={() => setMenuOpen(!isMenuOpen)}>
            <i />
            <i />
            <i />
          </Toggle>
        </Container>
      </header>
      <Menu onClickLink={onClickLink} />
    </Fragment>
  );
};

const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  height: ${rem(64)};
  border-radius: inherit;
  display: flex;
  z-index: 2;
  pointer-events: auto;

  & > * {
    display: inline-flex;
    align-items: center;
    height: inherit;
  }
`;

const Logo = styled(Link)`
  font-size: ${rem(16)};
  padding: 0 ${rem(32)};
  flex-grow: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.blackBorder};

  ${Icon} {
    ${media.mobile`
      &[data-id='logo'] {
        display: none;
      }
    `}

    ${media.fromMobile`
      &[data-id='logo-mobile'] {
        display: none;
      }
    `}
  }

  ${media.mobile`
    padding: 0 ${rem(24)};
  `}
`;

const Breadcrumb = styled.div`
  position: relative;
  padding: 0 ${rem(24)};
  overflow: hidden;
  flex-grow: 3;

  &:before {
    content: '';
    display: block;
    width: ${rem(16)};
    height: 2px;
    background: ${({ theme }) => theme.colors.accent};
  }

  span {
    position: absolute;
    left: ${rem(32 + 24)};
    ${fontNarrow};
    white-space: nowrap;
    font-weight: 600;
    font-size: max(12px, ${rem(12)});
    line-height: 1.25;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    vertical-align: top;
  }

  ${media.mobile`
    span {
      max-width: 65%;
      white-space: break-spaces;
    }
  `}
`;

const Toggle = styled.button`
  ${buttonReset};
  position: relative;
  width: ${rem(64)};
  margin-left: auto;
  border-left: 1px solid ${({ theme }) => theme.colors.blackBorder};

  i {
    display: block;
    width: max(16px, ${rem(16)});
    height: 2px;
    background: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ${({ theme }) => theme.easings.principle};
    will-change: transform;

    &:nth-child(1) {
      transform: translate(-50%, -50%) translateY(-7px);
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%) scaleX(${12 / 16}) translateX(-3px);
    }

    &:nth-child(3) {
      transform: translate(-50%, -50%) translateY(7px);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      i {
        &:nth-child(2) {
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  [data-menu-open='true'] & {
    i {
      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(-45deg) scaleX(${Math.sqrt(2)});
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(45deg) scaleX(${Math.sqrt(2)});
      }
    }
  }
`;

export const Header = styled(HeaderComponent)`
  position: absolute;
  padding: ${rem(8)};
  top: 0;
  right: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.header};
  transition-property: none;
  transition-duration: 0.5s;
  transition-timing-function: ${({ theme }) => theme.easings.principle};
`;
