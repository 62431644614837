import React, { createContext, useState, useContext } from 'react';
import { useMedia, useMount, useWindowSize } from 'react-use';
import { mqMobile, mqPortrait } from 'styles/media';

export const GlobalContext = createContext({
  savedScrollTop: 0,
  scrollEnabled: true,
  headerHeight: null,
});

const GlobalProvider = ({ children }) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [savedScrollTop, setSavedScrollTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [isClient, setClient] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isNewsletterOpen, setNewsletterOpen] = useState(false);
  const [menuTitle, setMenuTitle] = useState(null);
  const isMobile = useMedia(mqMobile);
  const isPortrait = useMedia(mqPortrait);
  const { width: ww, height: wh } = useWindowSize(null, null);

  const handleLoad = () => {
    setLoaded(true);
    window.removeEventListener('load', handleLoad);
  };

  useMount(() => {
    setClient(true);
    if (document.readyState === 'complete') {
      setLoaded(true);
    } else {
      window.addEventListener('load', handleLoad);
    }
  });

  return (
    <GlobalContext.Provider
      value={{
        isLoaded,
        setLoaded,
        isMobile,
        isPortrait,
        isMenuOpen,
        setMenuOpen,
        isNewsletterOpen,
        setNewsletterOpen,
        menuTitle,
        setMenuTitle,
        ww,
        wh,
        isClient,
        headerHeight,
        setHeaderHeight,
        scrollEnabled,
        setScrollEnabled,
        savedScrollTop,
        setSavedScrollTop,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw Error('useGlobalContext must be used inside a `GlobalProvider`');
  }
  return context;
};
