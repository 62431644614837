import { css, keyframes } from 'styled-components';

export const linkReset = css`
  text-decoration: none;
  outline: none;
  color: currentColor;
  cursor: pointer;
  letter-spacing: inherit;
`;

export const buttonReset = css`
  text-decoration: none;
  letter-spacing: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
  text-align: inherit;
  text-transform: inherit;
`;

export const hiddenScrollbarCSS = css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const slideIn = keyframes`
  from {
    clip-path: inset(-1em -1em 100% -1em);
    transform: translate3d(0, 100%, 0);
  }

  to {
    clip-path: inset(-1em -1em 0% -1em);
    transform: translate3d(0, 0%, 0);
  }
`;

export const splittedRevealCSS = css`
  visibility: hidden;

  [data-in-view='true'] &.splitting {
    visibility: visible;

    .word {
      position: relative;
      display: inline-block;
      animation: ${slideIn} 1.2s ${({ theme }) => theme.easings.circOut} both;
      animation-delay: calc(150ms + 100ms * var(--word-index));
    }
  }
`;
