import { createGlobalStyle } from 'styled-components';
import { rem } from 'styles/utils';
import media from 'styles/media';

const GlobalStyle = createGlobalStyle`
  :root {
    --margin: ${rem(32)};
    --viewport-height: ${({ viewportHeight }) => viewportHeight && `${viewportHeight}px`};
    --header-height: ${({ headerHeight }) => headerHeight && `${headerHeight}px`};
    --scroll-padding-top: ${rem(8)};
  }

  ${media.mobile`
    :root {
      --margin: ${rem(16)};
    }
  `}

  html {
    font-size: max(11px, ${(16 / 1440) * 100}vw);
    line-height: ${({ theme }) => theme.lineHeight.base};
    background: ${({ theme }) => theme.colors.white};
    -webkit-font-smoothing: antialiased;
    scroll-padding-top: var(--scroll-padding-top);

    ${media.mobile`
      font-size: 16px;
    `}
  }

  body {
    position: ${({ scrollEnabled }) => (scrollEnabled ? 'relative' : 'fixed')};
    overflow-y: ${({ scrollEnabled }) => (scrollEnabled ? null : 'scroll')};
    overflow-x: hidden;
    width: 100%;
    font-size: ${({ theme }) => `max(${theme.fontSize.normal}px, ${rem(theme.fontSize.normal)})`};
    letter-spacing: 0.04em;
    line-height: ${({ theme }) => theme.lineHeight.normal};
    overscroll-behavior: none;
  }

  .scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-only {
    ${media.fromMobile`
      display: none !important;
    `}
  }

  .no-mobile {
    ${media.mobile`
      display: none !important;
    `}
  }

  .reveal {
    transform: translateY(40px);
    opacity: 0;
  }

  [data-in-view='true'] .reveal {
    transition-property: transform, opacity;
    transition-duration: .5s, 1s;
    transition-delay: calc(var(--delay) * 1s);
    transition-timing-function: ${({ theme }) =>
      `${theme.easings.quadOut}, ${theme.easings.circOut}`};
    transform: translateY(0);
    opacity: 1;
  }
`;

export default GlobalStyle;
