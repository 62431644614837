import { propsToDom, propsToLink, createSmartRandom } from 'core/helpers';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { useGlobalContext } from 'store/GlobalProvider';
import { rem } from 'styles/utils';
import { usePageContext } from 'store/PageProvider';
import { Icon } from './Icon';
import { Image } from './Image';
import { Link } from './Link';
import { fontCondensed, h1 } from 'styles/typography';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useSortableData } from 'hooks/useSortableData';
import media from 'styles/media';
import beziers from 'core/beziers';

const KEYS = ['date', 'name', 'category'];
const POSITIONS = ['tl', 'tm', 'tr', 'bl', 'bm', 'br', 'ml', 'mr'];
const TRANSITION = { type: 'tween', duration: 0.8, ease: beziers.principle };

const MenuComponent = ({ onClickLink, ...props }) => {
  const $scroll = useRef(null);
  const { siteData } = usePageContext();
  const { isMenuOpen, setSavedScrollTop, setScrollEnabled } = useGlobalContext();
  const { main_menu, menu_default_title, projects: rawProjects, ...i18n } = siteData;
  const [hoverImage, setHoverImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState('tl');
  const scaleY = useMotionValue(0);
  const scaleYChild = useTransform(scaleY, (scale) => 1 / scale);

  // Filter & make data sortable
  const projects = useMemo(
    () => rawProjects.filter(({ date, category, name }) => !!date && !!category && !!name),
    [rawProjects]
  );

  const {
    items: sortedProjects,
    sortConfig,
    requestSort,
  } = useSortableData(projects, { key: 'date', direction: 'DESC' });

  // Scroll management
  useIsomorphicLayoutEffect(() => {
    if (isMenuOpen) {
      $scroll?.current?.scrollTo(0, 0);
      const { scrollTop } = document.scrollingElement || document.documentElement;
      setSavedScrollTop(scrollTop);
      animate(scaleY, 1, TRANSITION);
    } else {
      animate(scaleY, 0, TRANSITION);
    }
  }, [isMenuOpen]);

  // hack
  useEffect(() => {
    return scaleY.onChange((value) => {
      if (value === 0) {
        setScrollEnabled(true);
        setSavedScrollTop(0);
      } else {
        setScrollEnabled(false);
      }
    });
  }, []);

  // Hover management
  const getRandomPosition = useMemo(() => createSmartRandom(POSITIONS), []);

  const onHoverProject = (image) => {
    if (image) {
      setHoverImage(image);
      setHoverPosition(getRandomPosition());
    } else {
      setHoverImage(null);
    }
  };

  return (
    <div {...propsToDom(props)}>
      <motion.div style={{ originY: 0, scaleY }}>
        <motion.div style={{ originY: 0, scaleY: scaleYChild }}>
          <Container>
            <Left ref={$scroll} className="scrollable">
              <Table onMouseLeave={() => onHoverProject(null)}>
                <Head>
                  {KEYS.map((key) => (
                    <span
                      key={key}
                      onClick={() => requestSort(key)}
                      data-key={key}
                      data-direction={sortConfig.key === key ? sortConfig.direction : null}
                    >
                      <span>{i18n[`i18n_${key === 'date' ? 'year' : key}`]}</span>
                      <Icon id="arrow-right" />
                    </span>
                  ))}
                </Head>
                {sortedProjects.map(({ category, date, link, name, image }) => {
                  const hasLink = !!link?.url;
                  const hasImage = !!image?.url;
                  const linkProps = hasLink ? propsToLink(link) : {};
                  return (
                    <ProjectLink
                      {...linkProps}
                      as={hasLink ? Link : 'div'}
                      key={name + category + date}
                      data-image={hasImage}
                      onClick={hasLink ? onClickLink : null}
                      onMouseEnter={() => onHoverProject(hasImage ? image : null)}
                    >
                      <span data-key="date">{new Date(date).getFullYear()}</span>
                      <span data-key="name">{name}</span>
                      <span data-key="category">{category}</span>
                    </ProjectLink>
                  );
                })}
              </Table>
            </Left>
            <Right>
              {hoverImage && (
                <RandomImage
                  data-position={hoverPosition}
                  {...hoverImage}
                  key={hoverImage.url}
                  sizes={rem(320)}
                />
              )}
              <Nav isVisible={hoverImage === null}>
                {main_menu.map(({ link, link_text }, index) => {
                  return (
                    <NavLink
                      {...propsToLink(link)}
                      key={link.url + link_text + index}
                      onClick={onClickLink}
                    >
                      <Icon id="external" />
                      <span>{link_text}</span>
                    </NavLink>
                  );
                })}
              </Nav>
            </Right>
          </Container>
        </motion.div>
      </motion.div>
    </div>
  );
};

const Container = styled.div`
  height: calc(var(--viewport-height, 100vh) - ${rem(16 + 64)});

  & > div {
    border-top: 1px solid ${({ theme }) => theme.colors.blackBorder};
    background: ${({ theme }) => theme.colors.black};
  }

  ${media.mobile`
    background: ${({ theme }) => theme.colors.black};
    display: flex;
    flex-direction: column-reverse;
  `}

  ${media.fromMobile`
    display: grid;
    grid-column-gap: 1px;
    grid-template-columns: repeat(2, minmax(10px, 1fr));
    background: ${({ theme }) => theme.colors.blackBorder};
  `}
`;

const Right = styled.div`
  --padding: ${rem(32)};
  position: relative;
  padding: var(--padding);
`;

const RandomImage = styled(Image)`
  position: absolute;
  width: ${rem(320)};
  border-radius: 8px;
  overflow: hidden;

  &[data-position^='t'] {
    top: var(--padding);
  }

  &[data-position^='b'] {
    bottom: var(--padding);
  }

  &[data-position^='m'] {
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-position$='l'] {
    left: var(--padding);
  }

  &[data-position$='r'] {
    right: var(--padding);
  }

  &[data-position$='m'] {
    left: calc(50% - ${rem(320 / 2)});
  }

  ${media.mobile`
    display: none;
  `}
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${media.fromMobile`
    position: absolute;
    right: var(--padding);
    bottom: var(--padding);
    left: var(--padding);
  `}
`;

const activeNavLinkCSS = css`
  color: ${({ theme }) => theme.colors.accent};

  ${Icon} {
    opacity: 1;
    transform: scale(1);
  }

  span {
    transform: translateX(0);
  }
`;

const NavLink = styled(Link)`
  ${fontCondensed};
  ${h1};
  line-height: 1.1;

  display: flex;
  align-items: center;

  ${Icon} {
    font-size: ${rem(16)};
    margin-right: ${rem(16)};
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.5s ${({ theme }) => theme.easings.expoOut};
  }

  span {
    transform: translateX(${rem(-32)});
    transition: transform 0.5s ${({ theme }) => theme.easings.expoOut};
    will-change: transform;
  }

  &.is-active {
    ${activeNavLinkCSS};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${activeNavLinkCSS};
    }
  }

  ${media.mobile`
    font-size: ${rem(56)};
  `}
`;

const Left = styled.div`
  position: relative;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.black};
  z-index: 3;
`;

const Table = styled.div`
  display: table;
  width: 100%;

  & > * {
    display: table-row;
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.blackBorder};

    & > * {
      display: table-cell;
      padding: ${rem(8)} ${rem(16)};
    }
  }

  [data-key='category'] {
    text-align: right;
  }

  ${media.mobile`
    direction: rtl;

    & > * {
      direction: ltr;
    }

    [data-key='date'] {
      text-align: right;
    }

    [data-key='category'] {
      display: none;
    }
  `}
`;

const Head = styled.div`
  position: sticky;
  top: 0;
  font-weight: bold;
  background: ${({ theme }) => theme.colors.blackLight};
  white-space: nowrap;
  z-index: 3;

  [data-key] {
    cursor: pointer;
  }

  ${Icon} {
    position: relative;
    top: -2px;
    transform: rotate(-90deg);
    font-size: 8px;
    margin-left: ${rem(16)};
    vertical-align: baseline;
    line-height: 8px;
  }

  [data-direction] {
    ${Icon} {
      color: ${({ theme }) => theme.colors.accent};
    }

    &[data-direction='ASC'] ${Icon} {
      transform: rotate(90deg);
    }
  }
`;

const ProjectLink = styled.div`
  transition-property: opacity, background, color;
  transition-duration: 0.3s;
  transition-timing-function: ${({ theme }) => theme.easings.principle};

  &:not(a) {
    opacity: 0.5;
  }

  @media (hover: hover) and (pointer: fine) {
    &[data-image='true']:hover {
      background: ${({ theme }) => theme.colors.accent};
      color: ${({ theme }) => theme.colors.black};
      opacity: 1;
    }
  }
`;

export const Menu = styled(MenuComponent)`
  position: fixed;
  top: ${rem(8)};
  left: ${rem(8)};
  right: ${rem(8)};
  bottom: ${rem(8)};
  overflow: hidden;
  border-radius: 8px;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.menu};

  & > div {
    position: relative;
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: auto;
  }

  & > div > div {
    padding-top: ${rem(64)};
  }
`;
