import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { usePageContext } from 'store/PageProvider';
import { RichText } from 'components/RichText';
import { Link } from 'components/Link';
import { clamp, propsToDom, propsToLink } from 'core/helpers';
import { rem } from 'styles/utils';
import { Container } from 'components/Container';
import Swiper from 'components/Swiper';
import Retro from '-!svg-react-loader!../assets/svgs/retro.svg';
import Futur from '-!svg-react-loader!../assets/svgs/futur.svg';
import media from 'styles/media';
import { transitionFillHighlight } from 'styles/transitions';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const FooterComponent = ({ ...others }) => {
  const $logos = useRef(null);
  const [active, setActive] = useState(0);
  const { siteData } = usePageContext();
  const { footer_left_text, footer_right_text, footer_copyright, socials } = siteData;
  const isIntersecting = useIntersectionObserver($logos, {
    threshold: 0.0,
    once: true,
  });

  const socialsWithLinks = socials.filter(({ link }) => !!link?.url);

  const onSwipe = ({ deltaX, deltaY }) => {
    if (Math.abs(deltaX) > 50 && Math.abs(deltaY) < 100) {
      const next = clamp(active + Math.sign(deltaX), 0, 1);
      setActive(next);
    }
  };

  const onClickDot = (event) => {
    const index = Array.from(event.target.parentNode.children).indexOf(event.target);
    setActive(index);
  };

  return (
    <footer {...propsToDom(others)} data-in-view={isIntersecting}>
      <Swiper tag={Grid} style={{ '--index': active }} onSwipe={onSwipe}>
        <Left isActive={active === 0}>
          <RichText render={footer_left_text.richText} />
          <Dots>
            <i className="is-active" onClick={onClickDot} />
            <i onClick={onClickDot} />
          </Dots>
          <SVGContainer ref={$logos}>
            <Retro onClick={() => setActive(0)} />
          </SVGContainer>
        </Left>
        <Right isActive={active === 1}>
          <RichText render={footer_right_text.richText} />
          <Dots>
            <i onClick={onClickDot} />
            <i className="is-active" onClick={onClickDot} />
          </Dots>
          <SVGContainer>
            <Futur onClick={() => setActive(1)} />
          </SVGContainer>
        </Right>
      </Swiper>
      <Bottom>
        <p>
          {footer_copyright}&nbsp;{new Date().getFullYear()}
        </p>
        <Socials style={{ '--length': socialsWithLinks.length }}>
          {socialsWithLinks.map(({ link, name }) => (
            <Link {...propsToLink(link)} key={`${link.url}-${name}`}>
              {name}
            </Link>
          ))}
        </Socials>
      </Bottom>
    </footer>
  );
};

const SVGContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: auto;

  svg {
    width: 100%;
    height: auto;
    margin: auto auto 0 0;
    fill: ${({ theme }) => theme.colors.white};
    transform: translateY(100%);
    transition: transform 1.2s ${({ theme }) => theme.easings.circOut};

    [data-in-view='true'] & {
      transform: translateY(0%);
    }
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--margin);
  background: ${({ theme }) => theme.colors.black};
  pointer-events: auto;
  --mobile-offset: calc(var(--margin) * 2 + 50px);
  --mobile-shift: calc(var(--margin) * 2 + 75px);

  & > * {
    opacity: 0.05;
    transition: opacity 0.8s ${({ theme }) => theme.easings.expoOut};
  }

  ${RichText} {
    color: inherit;
    line-height: ${40 / 24};
    width: ${(496 / (720 - 64)) * 100}%;
  }

  ${media.fromMobile`
    ${RichText} {
      font-size: ${rem(24)};
    }

    @media (hover: hover) and (pointer: fine) {
      div:not(:hover) &:first-child {
        & > * {
          opacity: 1;
        }
      }

      &:hover {
        & > * {
          opacity: 1;
        }
      }
    }
  `}

  ${media.mobile`
    ${RichText} {
      font-size: ${rem(16)};
    }

    & > * {
      opacity: ${({ isActive }) => (isActive ? 1 : 0.05)};
    }

    ${SVGContainer} {
      transition: transform 1s ${({ theme }) => theme.easings.expoOut};
      width: calc(100% - var(--mobile-offset));
      will-change: transform;
    }
  `}
`;

const Left = styled(Col)`
  ${media.mobile`
    ${SVGContainer} {
      transform: translateX(calc(var(--index) * var(--mobile-shift) + (var(--index) * var(--mobile-offset))));
    }
  `}
`;

const Right = styled(Col)`
  svg {
    transition-delay: 0.15s;
  }

  ${media.mobile`
    ${SVGContainer} {
      transform: translateX(calc((-1 + var(--index)) * var(--mobile-shift) + (var(--index) * var(--mobile-offset))));
    }
  `}
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(24)};

  i {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    opacity: 0.1;
    margin-right: var(--margin);

    &.is-active {
      opacity: 1;
    }
  }

  ${media.fromMobile`
    display: none;
  `}
`;

const Bottom = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${rem(72)};
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.colors.blackBorder};
  text-align: center;

  ${media.mobile`
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    height: auto;

    p {
      order: 2;
      padding: var(--margin);
    }
  `}
`;

const Socials = styled.div`
  display: flex;
  align-items: center;
  box-shadow: inset 1px 0 0 0 ${({ theme }) => theme.colors.blackBorder};
  padding-left: var(--margin);
  margin-left: var(--margin);
  height: 100%;

  ${Link} {
    ${transitionFillHighlight};

    &:not(:last-child) {
      margin-right: var(--margin);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }

  ${media.mobile`
    display: grid;
    grid-template-columns: repeat(var(--length), 1fr);
    order: 1;
    margin-left: 0;
    padding: 0;
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.blackBorder};

    ${Link} {
      padding: var(--margin);

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(10px, 1fr));
  height: calc(100% - ${rem(72)});
  grid-column-gap: 1px;
  background: ${({ theme }) => theme.colors.blackBorder};
  pointer-events: none;

  ${media.mobile`
    transition: transform 1s ${({ theme }) => theme.easings.expoOut};
    transform: translateX(calc(var(--index) * -50%));
    will-change: transform;
    grid-column-gap: 0;
    height: auto;
    min-height: ${rem(424)};
    width: 200%;
  `}
`;

export const Footer = styled(FooterComponent)`
  height: calc(var(--viewport-height, 100vh));
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${media.mobile`
    height: auto;
  `}
`;
