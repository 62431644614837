import { useReducer, useRef } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import useScrollCallback from './useScrollCallback';

const initialState = {
  isFixed: false,
  isFixedVisible: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fixed':
      if (state.isFixed === action.payload) return state;
      return { ...state, isFixed: action.payload };
    case 'fixedVisible':
      if (state.isFixedVisible === action.payload) return state;
      return { ...state, isFixedVisible: action.payload };
    case 'fixedReset':
      if (state.isFixed === false && state.isFixedVisible === false) return state;
      return { ...state, isFixed: false, isFixedVisible: false };
    default:
      throw new Error();
  }
}

export function useStickyScrollUp() {
  const prevScrollTop = useRef(0);

  // ------------------------------------------------------------
  //    STATE
  // ------------------------------------------------------------
  const { scrollEnabled } = useGlobalContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const setFixed = (payload) => dispatch({ type: 'fixed', payload });
  const setFixedVisible = (payload) => dispatch({ type: 'fixedVisible', payload });

  // ------------------------------------------------------------
  //    CALLBACK
  // ------------------------------------------------------------
  useScrollCallback(({ scrollTop }) => {
    if (!scrollEnabled) return;
    scrollTop = scrollTop < 0 ? 0 : scrollTop;
    const { isFixed, isFixedVisible } = state;
    const prevScroll = prevScrollTop.current;
    const isScrollingUp = scrollTop < prevScroll;
    const isScrolledAfterBp = scrollTop > 400;

    if (!isFixed && isScrolledAfterBp) {
      setFixed(true);
    } else if (isFixed && isScrollingUp && isScrolledAfterBp) {
      setFixedVisible(true);
    } else if (isFixed && isFixedVisible && scrollTop > prevScroll) {
      setFixedVisible(false);
    }

    prevScrollTop.current = scrollTop;

    // if (Math.abs(scrollTop - prevScroll) > 10) {
    //   prevScrollTop.current = scrollTop;
    // }
  });

  // ------------------------------------------------------------
  //    STYLE
  // ------------------------------------------------------------
  let style = {};

  if (state.isFixed) {
    style = {
      ...style,
      position: 'fixed',
      bottom: '100%',
      top: 'auto',
      transitionProperty: 'transform',
    };
  }

  if (state.isFixedVisible) {
    style = {
      ...style,
      transitionProperty: 'transform',
      transform: 'translateY(100%)',
    };
  }

  if (scrollEnabled === false) {
    style.position = 'fixed';
  }

  return style;
}
