import { useState, useRef, useLayoutEffect, useEffect } from 'react';

const useIntersectionObserver = (target, options = {}) => {
  const {
    root = null,
    rootMargin = '0px',
    threshold = 1.0,
    enabled = true,
    once = false,
  } = options;

  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const refObserver = useRef(null);

  // ------------------------------------------------------------
  //    STATE
  // ------------------------------------------------------------
  const [isIntersecting, setIntersecting] = useState(null);

  function handleIntersection([entry]) {
    const intersects = entry.isIntersecting || entry.intersectionRatio > threshold;

    if (intersects !== isIntersecting) {
      setIntersecting(intersects);
    }

    if (intersects && once === true) {
      refObserver.current.disconnect();
    }
  }

  useLayoutEffect(() => {
    refObserver.current = new IntersectionObserver(handleIntersection, {
      rootMargin,
      root: root === null ? root : root.current,
      threshold,
    });

    return () => refObserver.current.disconnect();
  }, []);

  useEffect(() => {
    if (!target.current) return;

    if (enabled) {
      refObserver.current.observe(target.current);
    } else {
      refObserver.current.unobserve(target.current);
    }
  }, [enabled]);

  return isIntersecting;
};

export default useIntersectionObserver;
