import React from 'react';
import styled from 'styled-components';
import ArrowRight from '-!svg-react-loader!../assets/svgs/arrow-right.svg';
import ArrowDownBig from '-!svg-react-loader!../assets/svgs/arrow-down-big.svg';
import Logo from '-!svg-react-loader!../assets/svgs/logo.svg';
import LogoMobile from '-!svg-react-loader!../assets/svgs/logo-mobile.svg';
import External from '-!svg-react-loader!../assets/svgs/external.svg';

const IconComponent = ({ id, ...others }) => {
  switch (id) {
    case 'arrow-right':
      return <ArrowRight data-id={id} {...others} />;
    case 'arrow-down-big':
      return <ArrowDownBig data-id={id} {...others} />;
    case 'logo':
      return <Logo data-id={id} {...others} />;
    case 'logo-mobile':
      return <LogoMobile data-id={id} {...others} />;
    case 'external':
      return <External data-id={id} {...others} />;
    default:
      return null;
  }
};

export const Icon = styled(IconComponent)`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: top;

  &[data-id='logo'] {
    width: ${113 / 16}em;
  }

  &[data-id='logo-mobile'] {
    width: ${23 / 16}em;
  }
`;
