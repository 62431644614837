import styled, { css } from 'styled-components';
import { rem } from './utils';
import media from 'styles/media';

export const fontCondensed = css`
  font-family: 'herokid_condensed', sans-serif;
`;

export const fontUltraCondensed = css`
  font-family: 'herokid_ultra_condensed', sans-serif;
`;

export const fontNarrow = css`
  font-family: 'herokid_narrow', sans-serif;
`;

export const fontTitle = css`
  ${fontCondensed};
  font-weight: bold;
`;

// ------------------------------------------------------------
//    TITLES
// ------------------------------------------------------------

export const h1 = css`
  ${fontTitle}
  font-size: ${rem(120)};
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  ${media.mobile`
    font-size: ${rem(40)};
  `}
`;

export const Title1 = styled.h1`
  ${h1}
`;

export const h2 = css`
  ${fontUltraCondensed};
  font-weight: bold;
  font-size: ${rem(72)};
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  ${media.mobile`
    font-size: ${rem(32)};
  `}
`;

export const Title2 = styled.h2`
  ${h2};
`;

export const h3 = css`
  ${fontUltraCondensed}
  font-weight: bold;
  font-size: ${rem(64)};
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  ${media.mobile`
    font-size: ${rem(40)};
  `}
`;

export const Title3 = styled.h3`
  ${h3};
`;

export const h4 = css`
  ${fontUltraCondensed}
  font-weight: bold;
  font-size: ${rem(56)};
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  ${media.mobile`
    font-size: ${rem(40)};
  `}
`;

export const Title4 = styled.h4`
  ${h4};
`;

export const h5 = css`
  font-size: ${rem(28)};
  line-height: ${52 / 28};
  letter-spacing: 0.02em;

  ${media.mobile`
    font-size: ${rem(18)};
  `}
`;

export const Title5 = styled.h5`
  ${h5};
`;

export const h6 = css`
  font-size: ${rem(20)};
  line-height: ${32 / 20};
  letter-spacing: 0.02em;
`;

export const Title6 = styled.h5`
  ${h5};
`;

export const smallCSS = css`
  font-size: ${rem(14)};
  line-height: ${24 / 14};
  letter-spacing: 0.04em;
`;

export const Small = styled.p`
  ${smallCSS};
`;

export const labelCSS = css`
  ${fontNarrow};
  font-weight: 600;
  font-size: max(12px, ${rem(12)});
  line-height: ${16 / 12};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  vertical-align: top;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 2px;
    width: 14px;
    border-radius: 1px;
    background: ${({ theme }) => theme.colors.accent};
    margin-right: ${rem(16)};
  }
`;

export const Label = styled.p`
  ${labelCSS};
`;
