import { useEffect } from 'react';

const useScrollCallback = (callback) => {
  useEffect(() => {
    const handleScroll = (event) => {
      const { scrollTop, scrollLeft } = document.scrollingElement || document.documentElement;
      callback({ scrollTop, scrollLeft });
    };

    window.addEventListener('scroll', handleScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [callback]);
};

export default useScrollCallback;
