import React, { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { propsToDom } from 'core/helpers/propsToDom';
import { buttonReset } from 'styles/others';
import styled from 'styled-components';
import { useGlobalContext } from 'store/GlobalProvider';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const LinkComponent = (
  { children, to, activeClassName = 'is-active', isBroken = false, ...others },
  ref
) => {
  const { setNewsletterOpen } = useGlobalContext();

  if (!to || isBroken) {
    return <button {...propsToDom(others)}>{children}</button>;
  }

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    if (to === '/newsletter/') {
      others.onClick = (e) => {
        e.preventDefault();
        setNewsletterOpen(true);
      };
    }

    return (
      <GatsbyLink
        ref={ref}
        to={to}
        activeClassName={activeClassName}
        onClick={others.onClick}
        {...propsToDom(others)}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a
      ref={ref}
      href={to}
      {...propsToDom(others)}
      rel={others.target === '_blank' ? 'noopener' : null}
    >
      {children}
    </a>
  );
};

export const Link = styled(forwardRef(LinkComponent))`
  ${buttonReset}
`;
